export const GALLERY_ALIAS = 'galleryAlias' as const;

export interface IGalleryInitialState {
  loading: boolean,
  error: null,
  uploadImage: string,
  imageUrl: string,
  allImages: string[],
  deleteImage: null,
  currentSlideID: null | number,
  uploadImageState: null | any
}

export interface IResponseError {
  message: string;
}



