import { AxiosResponse } from 'axios';

export type TResponse<T> = Promise<AxiosResponse<T>>;

export enum EEnvironment {
  Stage = 'stage',
  Production = 'main',
  Development = 'development',
}

export const baseHostName =
  process.env.REACT_APP_API_BASE_HOST_NAME || 'wss://back.yoreactions.ru';

export const Stage =
  process.env.REACT_APP_API_STAGE || 'https://back.yoreactions.ru';

export const Production =
  process.env.REACT_APP_API_PRODUCTION || 'https://back.yoreactions.ru';

export const Development =
  process.env.REACT_APP_API_DEVELOPMENT || 'https://back.yoreactions.ru';

// export const baseHostName = 'wss://back.yoreactions.ru';
// export const Stage = 'https://back.yoreactions.ru';
// export const Production = 'https://back.yoreactions.ru';
// export const Development = 'https://back.yoreactions.ru';


