import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { GALLERY_ALIAS } from './types';
import { getUserID } from '../auth/selectors';
import { customAxiosImages } from '../../network/instance/castomAxios';

export const customAxiosUploadFile = createAsyncThunk(
  `${GALLERY_ALIAS}/customAxiosUploadFile`, async (params: any ,{getState, rejectWithValue}) => {
    const { onProgress, file, currentSlide} = params

    const formData = new FormData()
    formData.append('images', file)

    try {
      const id = getUserID(getState() as RootState);
      const response = await customAxiosImages.post(`/gallery/${id}/${currentSlide.id}`, formData,
        {
          onUploadProgress: ({ loaded, total }) =>
            onProgress?.({ percent: (loaded / (total ?? 1)) * 100 })
        }
      )
      if (response.status === 200) {
        return response
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Не удалось загрузить файл. Ошибка ${response.status}`,
        message: `Не удалось загрузить файл. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      })
    }
  })

export const getAllImages = createAsyncThunk(
  `${GALLERY_ALIAS}/getAllImages`, async (_, {getState, rejectWithValue}) => {

    try {
      const id = getUserID(getState() as RootState)
      const response =  await customAxiosImages.get(`/gallery/${id}/images`)
      if (response.status === 200) {
        return response
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Не удалось скачать файлы. Ошибка ${response.status}`,
        message: `Не удалось скачать файлы. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      })
    }
  }
)

export const getImage = createAsyncThunk(
  `${GALLERY_ALIAS}/getImage`, async (name:number[] | string, { rejectWithValue}) => {
    try {
      const response =  await customAxiosImages.get(`/gallery/${name}`)
      if (response.status === 200) {
        return response
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Не удалось скачать файл. Ошибка ${response.status}`,
        message: `Не удалось скачать файл. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      })
    }
  }
)

export const deleteImage = createAsyncThunk(
  `${GALLERY_ALIAS}/deleteImage`, async (imageId:number, { rejectWithValue}) => {
    try {
      const response = await customAxiosImages.delete(`/gallery/${imageId}`)
      if (response.status === 200) {
        return response.data
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Не удалось удалить файл. Ошибка ${response.status}`,
        message: `Не удалось удалить файл. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      })
    }
  }
)
